import { useEffect } from "react"
import PropTypes from "prop-types"

import { SIDEBAR_TOP_OFFSET } from "../constants"
import * as customAssessmentResponseTypes from "../types"

import ListGroup from "~/components/listGroup"
import Show from "~/design/Show"
import types from "~/types"
import PreviewMedicalBackgroundWidget from "~/views/customAssessments/PreviewMedicalBackgroundWidget"
import MedicalBackgroundWidget from "~/views/shared/MedicalBackgroundWidget"

function SideBar({ sections, medicalBackgroundWidgetEnabled, medicalBackground, chart, isPreview }) {
  useEffect(() => {
    // The offset controls where the scrollspy thinks the boundary for the section begins
    // relative to the top of the page.
    $("body").scrollspy({ offset: SIDEBAR_TOP_OFFSET + 5, target: "#response-sidebar" })
  }, [])

  return (
    <div
      id="response-sidebar"
      data-testid="response-sidebar"
      style={{ position: "sticky", top: `${SIDEBAR_TOP_OFFSET}px` }}
    >
      <h3 className="mb-2">Sections</h3>
      <ListGroup size="sm">
        {sections.map((section) => (
          <ListGroup.Item key={section.id} action href={`#response-section-${section.id}`}>
            {section.sectionName}
          </ListGroup.Item>
        ))}
        <ListGroup.Item action href="#completion-section">
          Complete
        </ListGroup.Item>
      </ListGroup>
      <Show when={medicalBackgroundWidgetEnabled}>
        {isPreview ? (
          <PreviewMedicalBackgroundWidget />
        ) : (
          <MedicalBackgroundWidget chart={chart} medicalBackground={medicalBackground} />
        )}
      </Show>
    </div>
  )
}

SideBar.propTypes = {
  sections: PropTypes.arrayOf(customAssessmentResponseTypes.schemaSection),
  medicalBackgroundWidgetEnabled: PropTypes.bool.isRequired,
  medicalBackground: types.medicalBackground,
  chart: types.chart,
  isPreview: PropTypes.bool.isRequired,
}

SideBar.defaultProps = {
  medicalBackgroundWidgetEnabled: false,
}

export default SideBar
