import { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import { IconButton } from "~/design/buttons"
import { EditIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"
import Card from "~/views/shared/patientDetails/Card"
import StrengthsForm from "~/views/shared/strengths/StrengthsForm"

function StrengthsCard({ chart, strengths: initialStrengths }) {
  const [strengths, setStrengths] = useState(initialStrengths)
  const [showModal, { open: openModal, close: closeModal }] = useModal()

  const displayStrengths = Object.keys(strengths)
    .map((strengthId) => strengths[strengthId])
    .sort((strengthA, strengthB) => strengthB.active - strengthA.active)

  return (
    <Card title="Strengths" actions={<IconButton Icon={EditIcon} label="Edit strengths" onClick={openModal} />}>
      {displayStrengths.length > 0 ? (
        <ul className="mb-0">
          {displayStrengths.map((strength) => (
            <li key={strength.id} className={classNames("mb-3", { "text-muted": !strength.active })}>
              {strength.description}
              {strength.active ? "" : " [INACTIVE]"}
            </li>
          ))}
        </ul>
      ) : (
        <em>This patient does not have any strengths for the selected agencies.</em>
      )}
      <StrengthsForm
        strengths={strengths}
        chart={chart}
        show={showModal}
        onClose={closeModal}
        onUpdate={setStrengths}
      />
    </Card>
  )
}

StrengthsCard.propTypes = {
  chart: types.chart.isRequired,
  strengths: PropTypes.objectOf(types.strength).isRequired,
}

export default StrengthsCard
