import { useState } from "react"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import RiskFactorsForm from "./RiskFactorsForm"

import { EditIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"

function RiskFactorListing({ riskFactor }) {
  const category = riskFactor.categoryName ? `(${riskFactor.categoryName})` : ""
  return (
    <li>
      {riskFactor.description} {category}
    </li>
  )
}

RiskFactorListing.propTypes = {
  riskFactor: types.riskFactor,
}

function RiskFactorSection({ chart, riskFactors: initialRiskFactors, categories }) {
  const [show, { close, open }] = useModal()
  const [riskFactors, setRiskFactors] = useState(initialRiskFactors)

  const activeRiskFactorIds = Object.keys(riskFactors).filter((riskFactorId) => riskFactors[riskFactorId].active)

  return (
    <SideBarToggle title="Risk Factors" actionLabel="Edit risk factors" ActionIcon={EditIcon} onActionIconClick={open}>
      <SideBarList bulleted emptyListMessage="This patient does not have any risk factors.">
        {activeRiskFactorIds.map((riskFactorId) => (
          <RiskFactorListing riskFactor={riskFactors[riskFactorId]} key={riskFactorId} />
        ))}
      </SideBarList>
      <RiskFactorsForm
        show={show}
        onClose={close}
        riskFactors={riskFactors}
        categories={categories}
        chart={chart}
        onUpdate={setRiskFactors}
      />
      <p className="font-weight-light mt-1">*Reported to MAPP as part of the sync process</p>
    </SideBarToggle>
  )
}

RiskFactorSection.propTypes = {
  chart: types.chart.isRequired,
  riskFactors: PropTypes.objectOf(types.riskFactor).isRequired,
  categories: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default RiskFactorSection
