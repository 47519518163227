import { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import { IconButton } from "~/design/buttons"
import { EditIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"
import Card from "~/views/shared/patientDetails/Card"
import RiskFactorsForm from "~/views/shared/riskFactors/RiskFactorsForm"

function RiskFactorListing({ riskFactor }) {
  const category = riskFactor.categoryName ? `(${riskFactor.categoryName})` : ""
  return (
    <li className={classNames("mb-3", { "text-muted": !riskFactor.active })}>
      {riskFactor.description} {category}
      {riskFactor.active ? "" : " [INACTIVE]"}
    </li>
  )
}

RiskFactorListing.propTypes = {
  riskFactor: types.riskFactor,
}

function RiskFactorsCard({ chart, riskFactors: initialRiskFactors, categories }) {
  const [riskFactors, setRiskFactors] = useState(initialRiskFactors)
  const [showModal, { open: openModal, close: closeModal }] = useModal()

  const displayRiskFactors = Object.keys(riskFactors)
    .map((riskFactorId) => riskFactors[riskFactorId])
    .sort((riskFactorA, riskFactorB) => riskFactorB.active - riskFactorA.active)

  return (
    <Card title="Risk Factors" actions={<IconButton Icon={EditIcon} label="Edit risk factors" onClick={openModal} />}>
      {displayRiskFactors.length > 0 ? (
        <ul className="mb-0">
          {displayRiskFactors.map((riskFactor) => (
            <RiskFactorListing riskFactor={riskFactor} key={riskFactor.id} />
          ))}
        </ul>
      ) : (
        <em>This patient does not have any risk factors for the selected agencies.</em>
      )}
      <RiskFactorsForm
        categories={categories}
        riskFactors={riskFactors}
        chart={chart}
        show={showModal}
        onClose={closeModal}
        onUpdate={setRiskFactors}
      />
    </Card>
  )
}

RiskFactorsCard.propTypes = {
  chart: types.chart.isRequired,
  riskFactors: PropTypes.objectOf(types.riskFactor).isRequired,
  categories: PropTypes.arrayOf(types.selectOption).isRequired,
}

export default RiskFactorsCard
