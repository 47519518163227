const downloadModal = {
  initialize() {
    $(document).on("click", "[data-trigger-download-modal-async]", this.handleAsyncClick.bind(this))
    $(document).on("click", "[data-trigger-download-modal-sync]", this.handleSyncClick.bind(this))
    $(document).on("click", "#download-modal .cancel-download-button", this.handleCancelClick.bind(this))
    $(document).on("click", "#download-modal .accept-download-button", this.handleAgreeClick.bind(this))
    $(document).on("click", "#download-modal .accept-download-button-sync", this.hideModal.bind(this))

    // Only enable buttons within the modal once the modal is fully visible.
    // This ensures the buttons can't be clicked before the modal is done
    // with its transition animation, since Bootstrap 4 won't allow modals to
    // be hidden mid-transition.
    $("#download-modal").on("shown.bs.modal", () => {
      $("#download-modal .accept-download-button").prop("disabled", false)
      $("#download-modal .cancel-download-button").prop("disabled", false)
    })

    // After hiding the modal, reset the state to account for any changes made
    $("#download-modal").on("hidden.bs.modal", () => {
      window.clearTimeout(this.checkExportStateTimeout)
      $("#download-modal .download-image.check").remove()
      $("#download-modal .accept-download-button-sync").remove()
      $("#download-modal #spinner-image").show()
      $("#download-modal .accept-download-button").show()
      $("#download-modal .accept-download-button").prop("disabled", true)
      $("#download-modal .cancel-download-button").prop("disabled", true)
    })
  },

  showModal: () => {
    $("#download-modal").modal({ backdrop: "static", keyboard: false })
  },

  hideModal: () => {
    $("#download-modal").modal("hide")
  },

  handleAsyncClick(e) {
    e.preventDefault()
    // using .attr rather than .data to avoid hitting the JQuery cache and getting a stale value
    const url =
      $(e.target).attr("data-trigger-download-modal-async") ||
      $(e.target).parent().attr("data-trigger-download-modal-async")
    this.showModal()
    $.post(url).done(this.handleExportResponse.bind(this)).fail(this.handleFailure.bind(this))
  },

  handleSyncClick(e) {
    e.preventDefault()
    // using .attr rather than .data to avoid hitting the JQuery cache and getting a stale value
    const url =
      $(e.target).attr("data-trigger-download-modal-sync") ||
      $(e.target).parent().attr("data-trigger-download-modal-sync")

    const target = $(e.target).attr("data-target") || $(e.target).parent().attr("data-target")

    $("#download-modal .accept-download-button").hide()
    $("#download-modal .phi-download-warning-buttons").append(
      `<a class="btn btn-primary accept-download-button-sync" ${
        target && `target="${target}"`
      } href="${url}">Ok, I agree</a>`
    )
    $("#download-modal .spinner").hide()
    this.showModal()
  },

  handleFailure({ responseJson }) {
    this.hideModal()
    const msg = (responseJson && responseJson.error) || "Download failed"
    // eslint-disable-next-line no-alert
    window.alert(msg)
  },

  handleAgreeClick(e) {
    this.accepted = true

    // File generation has completed and we can begin downloading immediately
    if (this.downloadUrl) {
      this.download()

      // OK was clicked before file generation has completed
    } else {
      $(e.target).text("Accepted")
      $(e.target).prop("disabled", true)
      $("#download-modal .cancel-download-button").prop("disabled", true)
    }
  },

  handleCancelClick() {
    this.hideModal()
    this.downloadUrl = null
    this.accepted = null
  },

  handleExportResponse(data) {
    if (data.state === "finished") {
      this.downloadUrl = data.resourceUrl
      this.indicateCompletion()

      if (this.accepted === true) {
        this.download()
      }
    } else if (data.state === "failed") {
      this.hideModal()
      this.showFlashDangerAlert("There was an unexpected problem while processing your file")
    } else {
      this.checkExportStateTimeout = window.setTimeout(
        () => $.get(data.resourceUrl).done(this.handleExportResponse.bind(this)),
        2000
      )
    }
  },

  download() {
    window.open(this.downloadUrl, "_self")
    this.hideModal()
    this.downloadUrl = null
    this.accepted = null
  },

  indicateCompletion() {
    $("#download-modal #spinner-image").hide()
    $("#download-modal .image-container").append(
      "<i style='color:green;' class='fa fa-check fa-5x download-image check'/>"
    )
  },

  showFlashDangerAlert(message) {
    $('[data-placeholder="flash"]').html(`<div class='alert alert-danger'>${message}</div>`)
  },
}

export default downloadModal
