import { useState } from "react"
import PropTypes from "prop-types"

import SideBarList from "../SideBarList"
import SideBarToggle from "../SideBarToggle"

import BarriersForm from "./BarriersForm"

import { EditIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"

function BarrierSection({ chart, barriers: initialBarriers }) {
  const [show, { close, open }] = useModal()
  const [barriers, setBarriers] = useState(initialBarriers)

  const activeBarrierIds = Object.keys(barriers).filter((barrierId) => barriers[barrierId].active)

  return (
    <SideBarToggle title="Barriers" actionLabel="Edit barriers" ActionIcon={EditIcon} onActionIconClick={open}>
      <SideBarList bulleted emptyListMessage="This patient does not have any barriers.">
        {activeBarrierIds.map((barrierId) => (
          <li key={barrierId}>{barriers[barrierId].description}</li>
        ))}
      </SideBarList>
      <BarriersForm show={show} onClose={close} barriers={barriers} chart={chart} onUpdate={setBarriers} />
      <p className="font-weight-light mt-1">*Required by MAPP as part of the sync process</p>
    </SideBarToggle>
  )
}

BarrierSection.propTypes = {
  chart: types.chart.isRequired,
  barriers: PropTypes.objectOf(types.barrier).isRequired,
}

export default BarrierSection
