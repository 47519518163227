import { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import { IconButton } from "~/design/buttons"
import { EditIcon } from "~/design/icons"
import { useModal } from "~/design/Modal"
import types from "~/types"
import BarriersForm from "~/views/shared/barriers/BarriersForm"
import Card from "~/views/shared/patientDetails/Card"

function BarriersCard({ chart, barriers: initialBarriers }) {
  const [barriers, setBarriers] = useState(initialBarriers)
  const [showModal, { open: openModal, close: closeModal }] = useModal()

  const displayBarriers = Object.keys(barriers)
    .map((barrierId) => barriers[barrierId])
    .sort((barrierA, barrierB) => barrierB.active - barrierA.active)

  return (
    <Card title="Barriers" actions={<IconButton Icon={EditIcon} label="Edit barriers" onClick={openModal} />}>
      {displayBarriers.length > 0 ? (
        <ul className="mb-0">
          {displayBarriers.map((barrier) => (
            <li key={barrier.id} className={classNames("mb-3", { "text-muted": !barrier.active })}>
              {barrier.description}
              {barrier.active ? "" : " [INACTIVE]"}
            </li>
          ))}
        </ul>
      ) : (
        <em>This patient does not have any barriers for the selected agencies.</em>
      )}
      <BarriersForm barriers={barriers} chart={chart} show={showModal} onClose={closeModal} onUpdate={setBarriers} />
    </Card>
  )
}

BarriersCard.propTypes = {
  chart: types.chart.isRequired,
  barriers: PropTypes.objectOf(types.barrier).isRequired,
}

export default BarriersCard
